// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

if (!window.AF) { window.AF = {}; }

AF.GoogleMapHandler = class GoogleMapHandler {

  constructor(args={}) {
    this.MAPS_KEY = 'AIzaSyC0Li1ESxS0UaKfDWwggp1qTwrVfRaZmgY';
    this.map = null;
    this.marker = null;
    this.submissionHandler = null;
    this.perform = this.perform.bind(this);
    this.initializeMap = this.initializeMap.bind(this);
    this.markAddressOnMap = this.markAddressOnMap.bind(this);
    this.updateCoordinates = this.updateCoordinates.bind(this);
    ({latitudeFormField: this.latitudeFormField, longitudeFormField: this.longitudeFormField, submissionHandler: this.submissionHandler} = args);
  }


  // this function can be called without arguments and by default will initialize
  // the map zoomed out on NZ.
  // when passed 'WITH_MARKER' as argument it will render the map zoomed in on a
  // marker placed at the latLng specified by the id:'x' and id:'y' fields of the form
  perform(mode) {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.MAPS_KEY;
    script.onload = this.initializeMap;

    return document.body.appendChild(script);
  }


  initializeMap() {
    const latitude = parseFloat(this.latitudeFormField.value);
    const longitude = parseFloat(this.longitudeFormField.value);
    let center = new (google.maps.LatLng)(-42.0, 174.0);
    let zoom = 5;
    if (latitude && longitude) {
      center = new (google.maps.LatLng)(latitude, longitude);
      zoom = 19;
    }
    const mapProp = {
      center,
      zoom,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false
    };
    this.map = new (google.maps.Map)(document.getElementById('map'), mapProp);
    //remove this is hiding map on page load
    if (latitude && longitude) {
      return this.markAddressOnMap({lat: latitude, lng: longitude});
    } else {
      return this.markAddressOnMap({lat: -41.2294, lng: 174.4831});
    }
  }


  markAddressOnMap(location) {
    if (this.marker === null) {
      this.marker = this.addMarker(location);
    } else {
      this.updateMarker((location));
      this.map.setZoom(19);
    }

    // for hiding map on page load
    // @marker = null
    // @marker = @addMarker(location)
    // @updateMarker (location)
    // @map.setZoom 19
    return this.map.setCenter(this.marker.getPosition());
  }


  updateCoordinates() {
    const lat = this.marker.getPosition().lat();
    const lng = this.marker.getPosition().lng();
    this.latitudeFormField.value = lat;
    return this.longitudeFormField.value = lng;
  }


  addMarker(latLng) {
    this.marker = new (google.maps.Marker)({
      position: latLng,
      map: this.map,
      draggable: true});
    this.updateCoordinates();
    this.marker.addListener('drag', () => {
      return this.updateCoordinates();
    });
    this.marker.addListener('dragend', () => {
      this.map.setCenter(this.marker.getPosition());
      return this.submissionHandler.updatePostcode(this.marker.getPosition());
    });
    return this.marker;
  }


  updateMarker(latLng) {
    this.marker.setPosition(latLng);
    return this.updateCoordinates();
  }
};

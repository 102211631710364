// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

if (!window.AF) { window.AF = {}; }
if (!window.AF.NZ) { window.AF.NZ = {}; }

AF.NZ.AddressSubmissionsHandler = class AddressSubmissionsHandler {
  constructor(args={}) {
    this.perform = this.perform.bind(this);
    this.setWasMultiAddress = this.setWasMultiAddress.bind(this);
    this.setWasBusiness = this.setWasBusiness.bind(this);
    this.loadAFScript = this.loadAFScript.bind(this);
    this.initializeGoogleMap = this.initializeGoogleMap.bind(this);
    this.initAF = this.initAF.bind(this);
    this.initializeMultiAddressListener = this.initializeMultiAddressListener.bind(this);
    this.initializeStreetNameListener = this.initializeStreetNameListener.bind(this);
    this.initializeIsBusinessListener = this.initializeIsBusinessListener.bind(this);
    this.initializeLevelListener = this.initializeLevelListener.bind(this);
    this.setRadioOnClickListener = this.setRadioOnClickListener.bind(this);
    this.setStreetNumberListener = this.setStreetNumberListener.bind(this);
    this.validateAddressToGeocode = this.validateAddressToGeocode.bind(this);
    this.geocodeAddress = this.geocodeAddress.bind(this);
    this.placeMarker = this.placeMarker.bind(this);
    this.updatePostcode = this.updatePostcode.bind(this);
    this.apiKey = args.apiKey; // This is the public key for AddressFinder suggest project in AF Hub
    this.POSTALCODE = "postal_code";
    this.NZ = ", NZ";
    this.address = null;
    this.googleMapHandler = null;
  }

  perform() {
    this.loadAFScript();
    this.initializeGoogleMap();
    this.initializeMultiAddressListener();
    this.initializeIsBusinessListener();
    this.initializeLevelListener();
    this.setStreetNumberListener();
    return this.initializeStreetNameListener();
  }
    // @setMapVisibility()


  setWasMultiAddress(was_multi_address) {
    return this.setVisibility(was_multi_address, "unit_form");
  }


  setWasBusiness(was_business) {
    return this.setVisibility(was_business, "business_name");
  }


  loadAFScript() {
    // connect to AF API
    const script = document.createElement("script");
    script.src = "https://api.addressfinder.io/assets/v3/widget.js";
    script.async = true;

    // add AF widget script to view
    script.onreadystatechange = this.initAF;
    script.onload = this.initAF;
    return document.body.appendChild(script);
  }


  initializeGoogleMap() {
    this.googleMapHandler = new AF.GoogleMapHandler({latitudeFormField: document.getElementById('y'), longitudeFormField: document.getElementById('x'), submissionHandler: this});
    const latitude = document.getElementById('y').value;
    const longitude = document.getElementById('x').value;
    return this.googleMapHandler.perform();
  }


  initAF() {
    // initialize addressfinder widget and bind it to view element
    const widget = new AddressFinder.Widget(
      document.getElementById("street"),
      this.apiKey,
      "NZ", {
      show_locations: true,
      location_params: { street: 1 },
      show_addresses: false,
      empty_content: `Looks like this is a new address - enter the street name, suburb and city. \
You may need to move the marker on the map below.`
    }
    );
    // set listener for addressfinder widget
    return widget.on("result:select", (value, item) => {
      // ignores suburb - well get that from the Fire Service data set later
      this.address = value;
      document.getElementById("street_name").value = item.street;
      document.getElementById("city").value = item.city;
      return this.validateAddressToGeocode();
    });
  }


  initializeMultiAddressListener() {
    const radioYes = document.getElementById("nz_address_submission_multi_address_true");
    const radioNo = document.getElementById("nz_address_submission_multi_address_false");
    this.setRadioOnClickListener(radioYes, "unit_form");
    return this.setRadioOnClickListener(radioNo, "unit_form");
  }


  initializeStreetNameListener() {
    const streetField = document.getElementById("street");
    return streetField.addEventListener("change", () => {
      this.address = document.getElementById("street").value;
      return this.validateAddressToGeocode();
    });
  }


  initializeIsBusinessListener() {
    const radioYes = document.getElementById("nz_address_submission_is_business_true");
    const radioNo = document.getElementById("nz_address_submission_is_business_false");
    this.setRadioOnClickListener(radioYes, "business_name");
    return this.setRadioOnClickListener(radioNo, "business_name");
  }


  initializeLevelListener() {
    const level_type = document.getElementById('level_type');
    const set_level_visibility = value => {
      return this.setVisibility(["Floor", "Level"].includes(value), "level_id");
    };

    set_level_visibility(level_type.value);

    return level_type.addEventListener("change", () => {
      return set_level_visibility(level_type.value);
    });
  }


  // initializeUnitListener: =>
  //   unit_type = document.getElementById('unit_type')
  //   selection = unit_type.value
  //   unit_types = ['Apartment', 'Kiosk', 'Room', 'Shop',
  //   'Suite', 'Villa', 'Flat', 'Unit']
  //   if selection == nil
  //     @setVisibility(true, "unit_id")
  //   else
  //     @setVisibility(false, "unit_id")
  //   unit_type.addEventListener "change", =>
  //     selection = unit_type.value
  //     if selection == unit_types
  //       @setVisibility(true, "unit_id")
  //     else
  //       @setVisibility(false, "unit_id")



  setRadioOnClickListener(radioButton, id_name) {
    return radioButton.addEventListener("click", () => {
      // convert string boolean to actual boolean
      let buttonValue;
      if (radioButton.value === "true") { buttonValue = true;
      } else { buttonValue = false; }
      return this.setVisibility(buttonValue, id_name);
    });
  }


  setStreetNumberListener() {
    const streetNoField = document.getElementById("street_no");
    return streetNoField.addEventListener("change", () => {
      // handle no widget street name selection
      if (!this.address) {
        this.address = document.getElementById("street").value;
      }
      return this.validateAddressToGeocode();
    });
  }


  // setMapVisibility: =>
  //   map_container = document.getElementById("map_container")
  //   @setVisibility(false, "map_container")
  //   streetNoField = document.getElementById("street_no")
  //   streetNoField.addEventListener "change", =>
  //     @setVisibility(true, "map_container")
  //     @googleMapHandler.initializeMap()


  validateAddressToGeocode() {
    const streetNo = document.getElementById("street_no").value;
    // handle empty street name field
    if (this.address) {
      document.getElementById("street").value = this.address;
    } else {
      alert("Street name is required to map your location");
      return;
    }
    const fullAddress = `${streetNo} ${this.address}${this.NZ}`;
    return this.geocodeAddress(fullAddress);
  }


  geocodeAddress(streetAddress) {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({address: streetAddress}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        return this.placeMarker(results);
      }
      else {}
    });
  }
        // alert("Geocode for this address was not successful: " + status)


  placeMarker(results) {
    this.googleMapHandler.markAddressOnMap(results[0].geometry.location);
    const postcode = this.extractGeocodeComponent(results, this.POSTALCODE);
    return this.setPostcode(postcode);
  }


  // display the unit entry fields if this street address has multiple addresses (e.g. units)
  setVisibility(value, id_name) {
    let classListName;
    if (value) { classListName = ""; } else { classListName = "hidden"; }
    return document.getElementById(id_name).className = classListName;
  }


  // scans the geocode results for a given component name, returning its long_name value
  extractGeocodeComponent(results, component) {
    const {
      address_components
    } = results[0];

    for (let current_component of address_components) {
      const {
        types
      } = current_component;

      for (let t of types) {
        if (t === component) {
          return current_component.long_name;
        }
      }
    }

    return null; // TODO check with Nigel if necessary
  }


  updatePostcode(latLng) {
    const geocoder = new google.maps.Geocoder();
    return geocoder.geocode({latLng}, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const postcode = this.extractGeocodeComponent(results, this.POSTALCODE);
        return this.setPostcode(postcode);
      } else {
        return alert("Geocode for this address was not successful: " + status);
      }
    });
  }


  setPostcode(postcode) {
    return document.getElementById("postcode").value = postcode;
  }
};
